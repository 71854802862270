<template>
	<div>
		<!--{{pages}} {{postType}}-->
		<ion-item>
			<div tabindex="0"></div>
			<ion-button v-if="step > 1" @click.prevent="step = step - 1">
				<ion-icon :icon="arrowBack"></ion-icon>
			</ion-button>
			<ion-button slot="end" v-if="step < 5" @click.prevent="step = step + 1">
				<ion-icon :icon="arrowForward"></ion-icon>
			</ion-button>
		</ion-item>
		<!-- Step 1 Page and Role -->
		<div v-if="step === 1">
			<!-- 
				Post Type
			-->
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-label position="stacked">Post Type</ion-label>
				<ion-select placeholder="Select One" v-model="postType">
					<ion-select-option value="post">Post</ion-select-option>
					<ion-select-option value="article">Article</ion-select-option>
					<ion-select-option value="concert">Concert</ion-select-option>
				</ion-select>
			</ion-item>
		</div>
		<!-- Step 2 Post Type -->
		<div v-if="step === 2">
			<div>
				<SelectPage @get-the-page="getPage" />
			</div>
			<div v-if="pages.length > 0" class="padding-top-md">
				<div v-for="page in pages" :key="page" class="margin-bottom-md border-bottom">
					<div class="padding-left-md padding-right-md">
						{{page.name}}
						<div>
							<ion-label position="stacked">Page Role</ion-label>
							<ion-select placeholder="Select One" v-model="page.role_id">
								<ion-select-option :value="1">Author of post</ion-select-option>
								<ion-select-option :value="2">Venue</ion-select-option>
								<ion-select-option :value="3">Artist</ion-select-option>
								<ion-select-option :value="4">Host</ion-select-option>
								<ion-select-option :value="5">Mentioned in post</ion-select-option>
							</ion-select>		
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Step 3 Post Form -->
		<div v-if="step === 3">
			<!-- Post -->
			<div v-if="postType == 'post' || postType == 'article'">
				<!-- Title 
				<ion-item>
					<div tabindex="0"></div>
					<ion-input v-model="title" placeholder="Title"></ion-input>
				</ion-item>-->
				<!-- Body 
				<div>
					<TextWrapper 
						:store="store.state"
						input-type="textarea"
						:is-mentions="true" 
						:is-hashtags="true" 
						:has-button="false" 
						:set-delimeter="null"
						@input-data="getInputData" />
				</div> -->
				<!-- URL -->
				<div v-if="showUrlForm === true">
					<LinkForm :store="store.state" @link-data="getLinkData" />
					<div v-if="isLinkSet === true">
						<div class="padding-md">
							<ion-button size="small" color="danger" @click.prevent="removeLink()">Remove Link</ion-button>
						</div>
						<div class="padding-left-md padding-right-md">
							<ion-label position="stacked">External link?</ion-label>
							<ion-select placeholder="Select One" v-model="external">
								<ion-select-option :value="0">No</ion-select-option>
								<ion-select-option :value="1">Yes</ion-select-option>
							</ion-select>		
						</div>
					</div>
				</div>
				<!-- Actions -->
				<ion-item lines="none">
					<div tabindex="0"></div><!-- bug fix for safari 14 -->
					<!--<ion-button v-if="imageUrl === null" slot="start" expand="full" size="small" color="light" @click="takePicture()">
						<ion-icon :icon="image"></ion-icon>
					</ion-button>
					<ion-button v-if="imageUrl != null" slot="start" expand="full" size="small" color="danger" @click="imageUrl = null">
						<ion-icon :icon="trash"></ion-icon>
					</ion-button>
					<ion-button slot="start" expand="full" size="small" color="light" @click.prevent="showUrlForm = !showUrlForm">
						<ion-icon :icon="link"></ion-icon>
					</ion-button>-->
					<ion-button v-if="url != null && url != ''" slot="end" expand="full" size="small" color="primary" @click.prevent="storePost()">
						Post
					</ion-button>
				</ion-item>
			</div>
			<!-- Concert -->
			<div v-if="postType == 'concert'">
				Concert Form
			</div>
		</div>
		<!-- Step 4 Genres -->
		<div v-if="step === 4">
			<PostGenres :post-id="postId" />
		</div>
		<!-- Step 5 Scenes -->
		<div v-if="step === 5">
			<PostScenes :post-id="postId" />
		</div>
	</div>
</template>

<script>
import { IonItem, IonButton, IonIcon, IonLabel, IonSelect, IonSelectOption, /*IonInput,*/ loadingController, toastController } from '@ionic/vue';
import { defineComponent, computed, ref } from 'vue';
import { useStore } from "vuex";
import { arrowForward, arrowBack, link, trash, image } from 'ionicons/icons';
import SelectPage from './SelectPage.vue';
import LinkForm from './LinkForm.vue'
//import TextWrapper from './TextWrapper.vue'
import PostGenres from './PostGenres.vue'
import PostScenes from './PostScenes.vue'
import apiClient from '../services/api';
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
const { Camera } = Plugins;

export default defineComponent({
	name: 'AdminPostForm',
	props: [],
	components: {
		IonItem, IonButton, IonIcon, SelectPage, IonLabel, IonSelect, IonSelectOption, /*IonInput, TextWrapper,*/ LinkForm, PostGenres, PostScenes
	},
	setup() {
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const step = ref(1);
		const pages = ref([]);
		const pageRole = ref(1);
		const postType = ref('article');
		const title = ref(null);
		const status = ref(1);
		const isLinkSet = ref(false);
		const bodyText = ref(null);
		const mentions = ref(null);
		const hashtags = ref(null);
		const showUrlForm = ref(true);
		const url = ref(null);
		const linkImage = ref(null);
		const linkTitle = ref(null);
		const linkDescription = ref(null);		
		const timeout = { default: 6000 }
		const toastMessage = ref(null);
		const imageUrl = ref(null);
		const isError = ref(false);
		const errors = ref([]);
		const external = ref(1);
		const postId = ref(0);

		function getPage(item) {
			item.role_id = 1
			pages.value.push({
				id: item.id,
				name: item.name,
				slug: item.slug,
				image: item.image,
				role_id: item.role_id
			})
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
				{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		function storePost() {
			presentLoading()
			apiClient.post('/api/store/post', {
				title: title.value,  
				body: bodyText.value, 
				status: status.value, 
				post_type: postType.value,
				image: imageUrl.value,
				url: url.value,
				link_image: linkImage.value,
				link_title: linkTitle.value,
				link_description: linkDescription.value,
				external: external.value,
				pages: pages.value      
			},
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				// Success
				if(response.data.message == 'success') {
					toastMessage.value = 'Your post has been created.'
					postId.value = response.data.post.id
					openToast()
					isError.value = false
					errors.value = []
					resetForm()
				}
				// Fail Validation
				if(response.data.message == 'fail-validation') {
					toastMessage.value = 'There was a problem.'
					openToast()
					isError.value = true
					errors.value = response.data.errors            
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You must be signed in to update your profile.'
					openToast()
				}
			}).catch(error => {
				// credentials didn't match
				isError.value = true
				errors.value = error.response.data.errors
			});        

		}

		function resetForm() {
			title.value = null
			bodyText.value = null
			status.value = 1
			imageUrl.value = null
			showUrlForm.value = false
			url.value = null;
			linkImage.value = null;
			linkTitle.value = null;
			linkDescription.value = null;
			isLinkSet.value = false;
			mentions.value = null;
			hashtags.value = null;
			external.value = 1
			pageRole.value = 1
			postType.value = 'article'
		}

		function removeLink() {
			isLinkSet.value = false;
			showUrlForm.value = false;
		}

		function getLinkData(e) {
			//if(e.length > 0) {
			isLinkSet.value = true;
			url.value = e.url;
			linkImage.value = e.linkImage;
			linkTitle.value = e.linkTitle;
			linkDescription.value = e.linkDescription;        
			//}

		}

		function getInputData(e) {
			if(e) {
				bodyText.value = e.text
				mentions.value = e.mentions
				hashtags.value = e.hashtags
			}
		}

		async function takePicture() {
			let isAvailable = true;
			if (!isAvailable) {
				// Have the user upload a file instead
				alert("No Camera Aailable");
			} else {
				// Otherwise, make the call:
				try {
					const image = await Camera.getPhoto({
						quality: 90,
						allowEditing: true,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Prompt
					});
					console.log("image", image);
					// image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
					imageUrl.value = image.dataUrl;
					// can be set to the src of an image now
					console.log(image);
				} catch (e) {
					console.log("error", e);
				}
			}
		}

		return {
			authUser, step, arrowForward, arrowBack, pages, getPage, pageRole, postType, store, title, status, storePost, isError, errors, resetForm, image, trash, imageUrl, takePicture, link, showUrlForm, getLinkData, removeLink, url, linkImage, linkTitle, linkDescription, isLinkSet, getInputData, bodyText, mentions, hashtags, external, postId
		}
	},
	watch: {
		step: function() {

		}
	}
});
</script>