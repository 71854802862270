<template>
	<div>
		<!-- Form -->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-label position="stacked">URL</ion-label>
			<ion-input v-model="url" type="text"></ion-input>
		</ion-item>
		<!-- Action -->
		<div v-if="showGetLinkButton === true">
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-button expand="block" @click.prevent="doLinkPreview()">Get Link</ion-button>
			</ion-item>
		</div>
		<!-- Preview -->
		<div v-if="previewData != null">
			<!-- Cover Image -->
			<div v-if="previewData.cover != null && previewData.cover != ''">
				<img :src="previewData.cover">
				<div class="sm-padding-left sm-padding-right">
					<ion-button size="small" color="danger" @click.prevent="removeImage()">Remove Image</ion-button>
				</div>
			</div>
			<!-- Title -->
			<div v-if="previewData.title != null && previewData.title != ''" class="sm-padding-left sm-padding-right">
				<h4>{{previewData.title}}</h4>
				<p>{{previewData.description}}</p>
			</div>
			<!-- Action -->
			<ion-item v-if="url != null && url != '' && previewData != null">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-button @click.prevent="addLinkToPost()">Add URL to post</ion-button>
				<ion-button color="light" @click.prevent="resetForm()">Reset</ion-button>
			</ion-item>			
		</div>
	</div>
</template>
<script>
import { IonItem, loadingController, IonLabel, IonInput, IonButton } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import apiClient from '../services/api';

export default defineComponent( {
	components: { IonItem, IonLabel, IonInput, IonButton },
	name: 'LinkForm',
	props: ['store'],
	setup(props) {
		const previewData = ref(null);
		const timeout = { default: 6000 }
		const url = ref(null);
		const showLinkPreview = ref(false);
		const showGetLinkButton = ref(false);
		const linkHasBeenSubmitted = ref(false);

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		function resetForm() {
			previewData.value = null;
			url.value = null;
			showLinkPreview.value = false;
			linkHasBeenSubmitted.value = false
			showGetLinkButton.value = false
		}

		function isValidURL(url) {
			var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
			'(\\#[-a-z\\d_]*)?$','i');

			if(pattern.test(url)){
				return true;
			} else {
				return false;
			}
		}

		function getPreview(url) {
			presentLoading()
			apiClient.post('/api/url/preview', 
				{
					url: url,        
				},
				{
					headers: {
						'Authorization':  `Bearer ${props.store.authToken}`
					}
				}).then(response => {
					previewData.value = response.data
					console.log(response.data)
				});			
		}

		function removeImage() {
			previewData.value.cover = null
		}

		function doLinkPreview() {
			if(isValidURL(url.value) === true) {
				getPreview(url.value)
				showLinkPreview.value = true
				showGetLinkButton.value = false	
			} else {
				showLinkPreview.value= false
			}
		}		

		return { presentLoading, url, isValidURL, showLinkPreview, getPreview, previewData, resetForm, removeImage, doLinkPreview, showGetLinkButton, linkHasBeenSubmitted }
	},
	methods: {
		addLinkToPost: function() {
			var linkImage = null
			var linkTitle = null
			var linkDescription = null
			// Image
			if(this.previewData.cover != null) {
				linkImage = this.previewData.cover
			}
			// Title
			if(this.previewData.title != null) {
				linkTitle = this.previewData.title
			}
			// Description
			if(this.previewData.description != null) {
				linkDescription = this.previewData.description
			}
			this.showGetLinkButton = false
			this.linkHasBeenSubmitted = true
			this.$emit('link-data', {
				url: this.url,
				linkImage: linkImage,
				linkTitle: linkTitle,
				linkDescription: linkDescription
			})	
			this.previewData = null	
		}
	},
	watch: {
		url: function() {
			if(this.url != null && this.previewData === null) {
				if(this.linkHasBeenSubmitted === false) {
					this.showGetLinkButton = true
				}
			}
		},
		previewData: {
			handler() {
				if(this.url != null && this.previewData === null) {
					if(this.linkHasBeenSubmitted === false) {
						this.showGetLinkButton = true
					}
				}		
			},
			deep:true
		}
	}
});
</script>

<style scoped>
	.sm-padding-left {
		padding-left:15px;
	}
	.sm-padding-right {
		padding-right:15px;
	}
</style>